.report-details-view {
    &__button {
        border: none;
        font: inherit;
        color: inherit;
        background-color: transparent;
        outline: none;
        width: var(--icon-size);
        height: var(--icon-size);

        .iconic {
            width: 100%;
            height: 100%;
        }
    }
}