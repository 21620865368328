.reports-view {
    display: flex;
    flex-direction: column;
    height: 100vh;
    // height: calc(var(--vh, 1vh) * 100 - var(--navbar-height));
    // margin-top: var(--navbar-height);

    &__map-container {
        // width: 100%;
        position: relative;
        // border: solid green 1px;
        /* margin-top: var(--navbar-height); */
        // height: calc( 100vh - var(--navbar-height) );
        // height: 200px;
        flex: 1;
    }

    &__map {

        // border: solid red 1px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        // height: 100%;
    }

    &--show-list &__map-container {
        height: 0px;
        flex: 0;
        overflow: hidden;
    }

    &--show-map &__list {
        height: 0px;
        overflow: hidden;
    }
}
