/* Tailwind components */

.btn-primary {
	//  @apply text-white bg-petrol active:bg-petrol-darker h-10 px-4 py-4 md:py-0 rounded-md focus:outline-none focus:bg-petrol-darker disabled:opacity-50;
	 @apply text-white bg-petrol active:bg-petrol-darker h-10 px-2 rounded-md focus:outline-none focus:bg-petrol-darker disabled:opacity-50;
}

.btn-secondary {
	// @apply shadow-md border border-gray-lightest active:bg-gray-lighter px-4 py-4 md:py-0 rounded-md focus:outline-none focus:bg-gray-lighter;
	@apply shadow-md border border-gray-lightest active:bg-gray-lighter px-2 py-0 rounded-md focus:outline-none focus:bg-gray-lighter;
}

.btn-icon {
	@apply w-10 h-10 px-2 flex justify-center items-center focus:outline-none rounded-md;
}

.btn-icon-label {
	@apply h-10 px-2 flex justify-center items-center focus:outline-none rounded-md space-x-2;
}

.btn-icon-label-round {
	@apply h-10 w-10 px-2 flex justify-center items-center focus:outline-none rounded-full space-x-2;
}

.btn-icon-label-inline {
	@apply h-10 px-2 inline-flex justify-center items-center focus:outline-none rounded-md space-x-2;
}

.btn-switch {
	// @apply md:w-10 h-10 px-4 py-8 md:py-0 flex-1 text-center justify-center items-center focus:outline-none border-0 flex space-x-2;
	@apply w-10 h-10 px-2 py-0 flex-1 text-center justify-center items-center focus:outline-none border-0 flex space-x-2;
}

.btn-switch-fluid {
	// @apply md:h-10 px-4 py-8 md:py-0 flex-1 text-center justify-center items-center focus:outline-none flex space-x-2;
	@apply h-12 px-2 py-0 flex-1 text-center justify-center items-center focus:outline-none flex space-x-2;
}

.input {
	@apply bg-white shadow-md border border-gray-lightest px-4 py-4 rounded-md;
}

.label {
	@apply block mb-1;
}

.input-icon {
	@apply flex justify-center items-center;
}

.link {
	@apply underline text-petrol hover:text-petrol-darker;
}

// custom ol buttons
.ol-overlaycontainer-stopevent{
	// @apply absolute flex bottom-24 md:bottom-8 left-8 space-x-2 text-white;
	@apply absolute flex bottom-24 sm:bottom-16 left-8 space-x-2 text-white;
	// increase bottom from 8 to 16 due to issues with android phones (controls were partly off screen)
}

.ol-hidden{
	display: none;
}

.warning-layer-toggle,
.go-to-location,
.ol-zoom-out,
.ol-zoom-in{
	@apply bg-petrol w-10 h-10 flex justify-center items-center focus:outline-none rounded-full text-lg sm:text-xl sm:text-3xl;
}
.ol-zoom{
	@apply flex space-x-2 hidden md:flex;
}

// tiny tooltip like arrows
.arrow-left{
	@apply border-white w-0 h-0 border-solid border-t-8 border-b-8 border-r-8;
	border-bottom-color: transparent;
	border-top-color: transparent;
}

.warning-layer-toggle--hidden {
	@apply hidden;
}

.warning-layer-toggle--active {
	border: solid #ff5662 2px;
}
