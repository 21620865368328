:root {
    --font-color: black;
    --font-color-dampened: #aaaaaa;
    --font-size-small: 0.9em;
    --font-weight-normal: 400;
    --font-weight-semi-bold: 600;
    --background-color: white;
    --link-color: #6298a5;
    --navbar-height: 90px;
    --container-padding-sides: 1em;
    --container-padding-sides-mobile: 0.5em;
    --icon-size: 40px;
    --weather-icon-size: 40px;
}
