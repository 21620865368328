/* Iconic theme rules. Don't delete! */
/* Hide all icons that are waiting to be injected */
img.iconic {
    display: none;
  }

  /* Make sure filled and text elements only get fills */
  .iconic-property-fill, .iconic-property-text {
    stroke: none !important;
  }

  /* Make sure stroked elements only get strokes */
  .iconic-property-stroke {
    fill: none !important;
  }

  /* Theme-specific rules */

  .iconic * {
    fill: var(--link-color);
    stroke: var(--link-color);
  }

  .iconic.iconic--inverted * {
    fill: var(--background-color);
    stroke: var(--background-color);
  }

  .iconic.iconic--dampened * {
    fill: var(--font-color-dampened);
    stroke: var(--font-color-dampened);
  }

  /*# sourceMappingURL=theme-starter-kit.css.map */
