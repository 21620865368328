@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;700&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

html{ height: 100% }

@import './Variables.css';
@import './Iconic.css';
@import './Components.scss';
