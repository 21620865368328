.liquid-container {
    padding: 0;
    width: 100%;
    /* padding-top: var(--navbar-height); */
}

@media screen and (max-width: 768px) {
    .liquid-container {
        padding-top: 0;
    }
}


.liquid-container--flexible-height {
    min-height: 100vh;
}

.liquid-container--fixed-height {
    min-height: none;
    height: 100vh;
}
