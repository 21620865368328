.history-view {
    padding-top: 0.5em;
    padding-left: var(--container-padding-sides);
    padding-right: var(--container-padding-sides);
    padding-bottom: 2em;

    &__title {
        margin-bottom: 0.5em;
    }

    &__buttons-and-slider {
        width: 100%;
        display: flex;
        flex-wrap: no-wrap;
        align-items: center;
        margin-top: 1.5em;
    }

    &__buttons {
        width: 100px;
        flex: 0 0 auto;
        // position: relative;
        // top: 20px;
        padding-right: 1em;
    }

    &__button {
        border: none;
        font: inherit;
        color: inherit;
        background-color: transparent;
        outline: none;
        width: var(--icon-size);
        height: var(--icon-size);
        // padding: 0 !important;

        .iconic {
            width: 100%;
            height: 100%;
        }
    }

    &__slider {
        flex: 1;
        .rc-slider-track {
            background-color: #e9e9e9;
        }
    }

    &__limit {
        display: none;
        border-left: solid red 1px;
        position: absolute;
        top: 14px;
        height: 24px;
    }

    &__days {
        display: flex;
    }

    &__day {
        flex: 1;
        background: #eeeeee;
        text-align: center;
        padding: 0.3em;

        &:not(:last-child) {
            border-right: solid white 2px;
        }

        &:first-child {
            border-radius: 0 0 0 5px;
        }

        &:last-child {
            border-radius: 0 0 5px 0;
        }
    }

    &__range {
        position: absolute;
        top: 38px;
        text-align: center;
        border-top: solid 2px hsl(141, 71%, 48%);
    }
}